.dashboardMenu .ant-menu-item-selected {
    color: #22A89E;
}

.dashboardMenu  .ant-menu-submenu-selected  .ant-menu-submenu-title{
    color: #22A89E;
}

.dashboardMenu{
    border-right: none;
    overflow-y: auto;
    max-height: 100%;
}