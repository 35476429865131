.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.type {
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  background-color: white;
  height: 100px;
  width: 130px;
  justify-content: center;
  align-items: center;
  font-size: 200%;
  margin-top: 3%;
  margin-bottom: 3%;
  margin-right: 2%;
}
@media (max-width: 2460px) {
  .type {
    height: 90px;
    width: 120px;
    font-size: 200%;
  }
}
@media (max-width: 1722px) {
  .type {
    height: 70px;
    width: 100px;
    font-size: 150%;
  }
}
@media (max-width: 1436px) {
  .type {
    height: 70px;
    width: 90px;
    font-size: 125%;
  }
}
@media (max-width: 1291px) {
  .type {
    height: 65px;
    width: 80px;
    font-size: 115%;
  }
}
@media (max-width: 1150px) {
  .type {
    height: 65px;
    width: 75px;
    font-size: 100%;
  }
}
@media (max-width: 1076px) {
  .type {
    height: 60px;
    width: 70px;
    font-size: 80%;
  }
}
@media (max-width: 991px) {
  .type {
    height: 70px;
    width: 80px;
    font-size: 90%;
  }
}
@media (max-width: 967px) {
  .type {
    height: 65px;
    width: 70px;
    font-size: 75%;
  }
}
@media (max-width: 909px) {
  .type {
    height: 60px;
    width: 65px;
    font-size: 75%;
  }
}
@media (max-width: 767px) {
  .type {
    height: 70px;
    width: 90px;
    font-size: 100%;
  }
}
