.footerDiv{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.deleteBtn{
    background: #22A89E;
    border-radius: 10px;
    border: none;
    color: white;
    width: 80px;

}

.deleteBtn:hover{
    background-color: #22A89E ;
    color: white !important;
    border: none;
}

.cancelBtn{
    color: #22A89E;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    width: 80px;
}

.cancelBtn:hover{
    background: rgba(34, 168, 158, 0.25);
    color: #22A89E !important;
    border-radius: 10px;
}

.text{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.spin{
    margin-right: 5px;
}
