.error{
    color: #ed4444;
}

.submitButton {
    background-color: #22a89e;
    font-family: 'Nunito', sans-serif;
    color: white;
    width: 350px;
    height: 40px;
}

.imageUploaded{
    color: #22a89e;
}