.img{
    border-radius: 50%;
}

.imgContainer{
    display: inline-block;
    margin-left: 2px;
    margin-right: 2px;
}

.tagContainer{
    display: flex;
    flex-direction: column;
}
.tag{
    margin: 2px;
}