.mainContainer {
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.header {
  margin-top: 30px;
  display: flex;
}
.inputContainer {
  display: flex;
  flex-direction: row;
}

.inputDivider {
  margin: 1%;
  flex: 1;
}

.vector {
  margin-top: 3px;
  cursor: pointer;
  padding-left: 10px;
}
.subHeader {
  display: flex;
  flex-direction: row;
  padding-left: 6px;
}
.users {
  padding-left: 12px;
  padding-right: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0;
  margin-bottom: 0;
}

.details {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: black;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 1150px) {
  .mainContainer {
    flex-direction: column;
    scroll-behavior: auto;
  }
  .imageCropperToolsContainer {
    flex-direction: column;
  }
}

.table:hover {
  cursor: grab;
}

.tables {
  display: flex;
  flex-direction: column;
}

.loaderOverlay {
  font-size: 44px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.imageCropperToolsContainer {
  position: fixed;
  display: flex;
  bottom: 10px;
  right: 0px;
  justify-content: center;
  align-items: center;
}

.imageCropperToolsCover {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  justify-items: center;
}

.cropperItemDivider {
  margin-right: 5px;
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;
}

.cropperTextDivider {
  margin-right: 5px;
}

.imageContainer {
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: fill !important;
  box-shadow: 0 40px 50px grey;
}
