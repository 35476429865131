.mainContainer {
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.saveContainer {
  background-color: #e4e4e4;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 850px;
  padding: 10px 35px;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  align-items: center;
  justify-content: space-between; /* align items at the ends */
  width: 100%;
}

.closeSaveContainer {
  display: none;
}

.contentContainer {
  display: flex;
  align-items: center;
}
.closeIcon {
  width: 13px !important;
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.selectedText {
  color: #a1a1a1;
  margin-left: 20px;
}

.saveButton {
  background-color: #22a89e;
  color: white;
  display: flex;
  align-items: center;
  padding: 20px 30px;
  font-size: 15px;
  border: none;
}

.saveButton:hover {
  color: white !important;
}

.trashIcon {
  cursor: pointer;
  padding: 10px;
  width: 40px !important;
}

.trashIcon:hover {
  background-color: #d2d2d2;
  border-radius: 6px;
}
.alertModal {
  width: 350px !important;
}

.alertText {
  text-align: start;
  width: 200px;
  font-size: 16px;
}

/* Styles for the sub container */
.subContainer {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 35px;
  max-width: 1400px;
  min-width: 800px;
  min-height: 700px;
  border-radius: 6px;
}

/* Styles for the diffusion container */
.imagesContainer {
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

/* Styles for the diffusion */
.image {
  border-radius: 6px;
  cursor: pointer;
  aspect-ratio: 1;
}

.selected {
  padding: 10px;
  background-color: #22a89e40;
}

/* Styles for the pagination container */
.paginationContainer {
  margin-top: 30px;
}

@media only screen and (max-width: 1350px) {
  .imagesContainer {
    max-width: 800px;
  }

  .subContainer {
    min-width: 700px;
  }

  .saveContainer {
    max-width: 800px;
  }
}

@media only screen and (max-width: 1200px) {
  .imagesContainer {
    max-width: 700px;
  }

  .saveContainer {
    max-width: 700px;
  }

  .subContainer {
    min-width: 600px;
  }
}

@media only screen and (max-width: 1080px) {
  .imagesContainer {
    max-width: 600px;
  }

  .saveContainer {
    max-width: 600px;
  }

  .saveButton {
    padding: 15px 25px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 896px) {
  .saveContainer {
    width: 88vw;
    padding: 0px 35px;
  }
}

@media only screen and (max-width: 720px) {
  .saveContainer {
    width: 84vw;
  }
  .subContainer {
    min-width: 500px;
  }
}

@media only screen and (max-width: 420px) {
  .saveContainer {
    width: 80vw;
  }

  .subContainer {
    min-width: 80vw;
  }
}
