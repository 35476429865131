.mainContainer {
  background-color: #f5f5f5;
  width: 100vw;
}
.userNav {
  display: inline-flex;
  flex-direction: row;
  margin-top: 50px;
  padding-left: 50px;
}
.search {
  font-family: 'Nunito', sans-serif;
}

.selectUser{
  width: 175px;
  height: 30px ;
}

.imageContainer {
  display: flex;
  align-items: center;
}
.image {
  width: 16px;
}
.textContainer {
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.EmailInputContainer {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.emailInput {
  margin-right: 15px;
  padding-left: 10px;
  background-color: rgba(0, 0, 0, 0.07);
  height: 28px;
  border-radius: 6px;
  border: none;
}
.DateInput {
  margin-right: 15px;
  padding-left: 10px;
  background-color: rgba(0, 0, 0, 0.07);
  height: 28px;
  border-radius: 6px;
  border: none;
}
.totalNumberContainer {
  display: flex;
  align-items: center;
  padding-left: 40vw;
}
.totalNumber {
  color: #22a89e;
}
.tableContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.table {
  margin: 0 auto;
  width: 95%;
  padding-top: 20px;
}
.emailText {
  color: #22a89e;
  font-family: 'Inter', sans-serif;
}
.dateText {
  color: #22a89e;
  font-family: 'Inter', sans-serif;
}
.searchContainer {
  display: flex;
  flex-direction: row;
}
.iconsContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.informationIcon{
  margin-left:20px!important;
  font-size: 25px!important;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .userNav {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    padding-left: 50px;
  }
  .totalNumberContainer {
    display: flex;
    align-items: center;
    padding-left: 0;
  }
  .EmailInputContainer {
    margin-bottom: 10px;
  }
}

@media (max-width: 950px) {
  .mainContainer {
    height: auto;
  }
}

@media (max-width: 650px) {
  .searchContainer {
    padding-left: 60px;
  }
  .userNav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
    padding-left: 10px;
    padding-right: 30px;
  }
  .mainContainer {
    background-color: #f5f5f5;
    width: 65vw;
    height: auto;
  }
  .totalNumberContainer {
    padding-left: 25px;
  }
}

@media (max-width: 450px) {
  .mainContainer {
    width: 65vw;
  }
}

@media (max-width: 350px) {
  .searchContainer {
    padding-left: 30px;
  }
}
