.rc-pagination {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

.rc-pagination-item {
  justify-content: center;
  display: inline-flex;
  align-items: center;
  align-content: center;
  align-self: center;
  vertical-align: middle;
  border-radius: 5px;
  margin-right: 5px;
  background-color: rgba(0, 0, 0, 0.25);
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.rc-pagination-item-active {
  position: relative;
  width: 16px;
  justify-content: center;
  display: inline-flex;
  align-items: center;
  align-content: center;
  align-self: center;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  margin-right: 5px;
  height: 16px;
  cursor: pointer;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  display: none;
}


@media (max-width: 650px) {
  .rc-pagination-item {
    justify-content: center;
    display: inline-flex;
    align-items: center;
    align-content: center;
    align-self: center;
    vertical-align: middle;
    border-radius: 4px;
    margin-right: 5px;
    background-color: #d9d9d9;
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  .rc-pagination-item-active {
    position: relative;
    width: 14px;
    justify-content: center;
    display: inline-flex;
    align-items: center;
    align-content: center;
    align-self: center;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 7px;
    margin-right: 5px;
    height: 14px;
    cursor: pointer;
  }
}
