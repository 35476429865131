.personalDetailsTitle {
  color: #000;
  /*font-family: Hey Haters, serif ;*/
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
}

.label {
  color: #000;
  font-family: Noto Sans, serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 10px;
}

.profileImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #22a89e;
  width: 120px; /* Adjust the width and height as needed */
  height: 120px;
  border-radius: 50%; /* Creates a circular shape */
  overflow: hidden; /* Hides any overflow content outside the circle */
  border: 2px solid #fff; /* Optional: Add a border with a white background */
}

.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire circle */
}

.inputContainer {
  /*background: rgba(34, 168, 158, 0.25);*/
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #cdcdcd;
  margin-right: 100px;
  min-height: 20px;
}



.phoneInput {
  border-radius: 10px;
  border: 1px solid #cdcdcd;
}

.profile {
  width: 100%;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

@media (max-width: 767px) {
  .personalDetailsTitle {
    font-size: 24px;
  }
}
