.textModalContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
}

.title {
  font-size: 18px;
  font-family: 'Nunito', sans-serif;
}

.textContainer {
  padding-top: 10px;
  padding-bottom: 220px;
  border: 0;
  background-color: #f5f5f5;
}

.textContainer:hover {
  background-color: #f5f5f5;
}

.enterContainer {
  display: flex;
  justify-content: center;
}

.enter {
  background-color: #22a89e;
  font-family: 'Nunito', sans-serif;
  color: white;
  width: 350px;
  height: 40px;
  margin-top: 10px;
}

@media (max-width: 500px) {
  .enter {
    width: 260px;
  }
}
