.margins{
    height: 20px;
}

.mainContainer {
    justify-content: center;
    height: auto;
    background-color: #f5f5f5;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90vw;
}

.header {
    margin-top: 30px;
    display: flex;
    padding-left: 25px;
}

.detailsTitle {
    display: flex;
    flex-direction: column;
}

.users {
    padding-left: 12px;
    padding-right: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 0;
    margin-bottom: 0;
}

.details {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: black;
    margin-top: 0;
    margin-bottom: 0;
}

.title {
    display: flex;
    padding-left: 42px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #000000;
}

.subTitle {
    padding-top: 20.5px;
    padding-left: 5px;
    color: #22a89e;
}

.line {
    margin: auto;
    width: 95%;
    border: 1px solid rgba(0, 0, 0, 0.4);
}

.vector {
    margin-top: 3px;
    cursor: pointer;
    padding-left:25px;
}


.subHeader {
    display: flex;
    flex-direction: row;
    padding-left: 6px;
}
