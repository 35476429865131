@media (max-width: 1292px) {
  .antd_ThereIsData .ant-table-thead {
    height: 77px !important;
  }
}

@media (max-width: 1145px) {
  .antd_noData .ant-table-thead {
    height: 77px !important;
  }
}
