.errorFormItem {
color: red !important;
}

.navContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    height: 40vh;
}
.textContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    line-height: 0;
}
.imageContainer{
    margin-bottom: 20px;
}
.title{
    font-size: 72px;
    color: #22A89E;
    font-family: 'Nunito', sans-serif;
}
.description{
    color: #00000040;
    font-size: 14px;
    font-family: 'Nunito', sans-serif;
}

.loginFormContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}

.signInContainer{
 display: flex;
    justify-content: center;
}
.signIn{
    background-color: #22A89E ;
    color: white;
    width: 444px;
    height: 40px;
}
.rememberMeContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}
.userIcon{
    font-size: 24px;
    color: #292D32;
}
.passwordIcon{
    font-size: 24px;
    color: #292D32;
}
.userContainer{
    padding: 10px;
    border: 0;
    background-color: #0000000A ;
}

.passwordContainer{
    padding: 10px;
    border: 0;
    background-color: #0000000A ;
}

.forgotText{
    color: #22A89E;
    margin-top: 5px;
}
.footerContainer{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    flex-direction: column;
    height: 27vh;

}

.footerText{
    color: #00000040;
    font-size: 13px;
    font-family: 'Nunito', sans-serif;
}

@media (max-width: 500px) {
    .title{
        font-size: 48px;
        color: #22A89E;
        font-family: 'Nunito', sans-serif;
    }
    .signIn{
        background-color: #22A89E ;
        color: white;
        width: 222px;
        height: 35px;
    }
}
