.mainContainer {
    justify-content: center;
    height: auto;
    background-color: #f5f5f5;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90vw;
}

.header {
    margin-top: 30px;
    display: flex;
    padding-left: 10px;
}


.search {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.vector {
    margin-top: 3px;
    cursor: pointer;
    padding-left: 12px;
}

.users {
    padding-left: 12px;
    padding-right: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 0;
    margin-bottom: 0;
}

.details {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: black;
    margin-top: 0;
    margin-bottom: 0;
}

.title {
    display: flex;
    padding-left: 42px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #000000;
}

.image{
    border-radius: 50%;
}


.mainTitle {
    padding-left: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 0;
    margin-bottom: 0;
    white-space: pre-wrap;
}

.separator{
    margin-top: 10px;
    margin-bottom: 10px;
}

.editIcon {
    cursor: pointer;
}

.line {
    margin: auto;
    width: 95%;
    border: 1px solid rgba(0, 0, 0, 0.4);
}

.closeModelText {
    display: flex;
    padding-left: 35px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.5);
}

.subHeader {
    display: flex;
    flex-direction: row;
    padding-left: 6px;
}

.blanksSubHeader {
    display: flex;
    flex-direction: row;
    padding-left: 17px;
}

.inputsColumn {
    display: flex;
    flex-direction: row;
    padding-top: 3px;
    padding-left: 35px;
    margin-right: 8px;
}

.inputs {
    margin-right: 15px;
    padding-left: 10px;
    margin-left: 40px;
    background-color: rgba(0, 0, 0, 0.07);
    height: 28px;
    border-radius: 6px;
    border: none;
}

.select{
    width:173px;
}

.search {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.searchIconImage {
    padding-right: 5px;
}

.table {
    margin: 0 auto;
    width: 96%;
    padding-top: 20px;
}

.space {
    width: 5px;
    height: 1px;
}

.icon {
    cursor: pointer;
}
.id{
    color: #22A89E;
}

.informationIcon{
    font-size: 25px!important;
    cursor: pointer;
}

.printerIcon{
    font-size: 25px!important;
    cursor: pointer;
}

@media (max-width: 1032px) {
    .inputs {
        margin-left: 5px;
        margin-top: 5px;
    }
}

@media (max-width: 700px){
    .title {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}

@media (max-width: 584px) {
    .mainContainer {
        height: auto;
    }
    .search {
        flex-direction: column;
        justify-content: center;
    }

    .inputsColumn {
        padding-left: 0;
    }
}

@media (max-width: 464px) {

    .closeModelText {
        padding-left: 0;
        justify-content: center;
    }

    .title {
        padding-left: 0;
        justify-content: center;
    }

    .blanksSubHeader {
        padding-left: 0;
        width: 50vw;
    }

    .mainTitle {
        font-size: 3.5vw;
    }


    .inputs {
        width: 30vw;
    }
}
