.title {
  background-color: #e8e8e8;
  padding-bottom: 15px;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
}

.image {
  border-radius: 8px;
  aspect-ratio: 1;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  padding-top: 5px;
}
