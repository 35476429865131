.descriptionContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  font-weight: bold;
  margin-right: 1%;
}
.subContainer {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  justify-content: center;
  font-size: 18px;
}
.totalNumber {
  height: 15%;
}
.circle {
  display: flex;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 4%;
}
.typeName {
  font-size: 190%;
  display: flex;
  align-items: center;
}
@media (max-width: 2460px) {
  .typeName {
    font-size: 200%;
  }
}
@media (max-width: 1910px) {
  .typeName {
    font-size: 170%;
  }
}
@media (max-width: 1722px) {
  .typeName {
    font-size: 150%;
  }
}
@media (max-width: 1436px) {
  .typeName {
    font-size: 125%;
  }
}
@media (max-width: 1291px) {
  .typeName {
    font-size: 115%;
  }
}
@media (max-width: 1150px) {
  .typeName {
    font-size: 100%;
  }
}
@media (max-width: 991px) {
  .typeName {
    font-size: 90%;
  }
}
@media (max-width: 767px) {
  .descriptionContainer {
    margin-left: 1%;
  }
  .typeName {
    font-size: 100%;
  }
}
