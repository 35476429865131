.input {
    background-color: #e7e7e7;
    padding-bottom: 10px;
    padding-top: 10px;
    border: 0;
    border-radius: 6px;
    margin-bottom: 15px;
    padding-left: 10px;
}

.uploadContainer{
    display: flex;
}

.spin{
    margin-right: 5px;
}

.submitButton {
    background-color: #22a89e;
    font-family: 'Nunito', sans-serif;
    color: white;
    width: 350px;
    height: 40px;
}

.addContainer{
    width: 450px;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.textContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.imageUploaded{
    color: #22a89e;
}

@media (max-width: 460px) {
    .submitButton {
        width: 250px;
    }
}

@media (max-width: 330px) {
    .submitButton {
        width: 200px;
    }
}