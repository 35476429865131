.loadingContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}
.loading .ant-spin-dot .ant-spin-dot-item
{
    background-color: #22A89E !important;
}

.loading .ant-spin-text
{
    color: #22A89E !important;
}


