.customCheckbox {
  position: absolute;
  top: 5px;
  left: 15px;
  z-index: 1000;
}

.customCheckbox .ant-checkbox-inner {
  border-radius: 50%;
  background-color: transparent;
}

/*Changing default border color on hover*/
.customCheckbox .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: #22a89e !important;
}

/*Changing default background color on hover after checked*/
.customCheckbox:hover .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #22a89e !important;
}

/*Changing default border color after checked*/
.customCheckbox .ant-checkbox-checked:after {
  border: 2px solid #22a89e !important;
}

.customCheckbox .ant-checkbox:after {
  border-radius: 50%;
}

/*Change checkbox background color after being checked*/
.customCheckbox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #22a89e;
}

/*preview image in gallery section*/
.previewModal {
  width: auto !important;
  max-width: 40% !important;
}

.previewModal .ant-modal-content {
  padding: 0px !important;
}

@media (max-width: 1200px) {
  .customCheckbox .ant-checkbox-inner {
    width: 10px;
    height: 10px;
  }

  .customCheckbox {
    top: 0px;
    left: 15px;
  }
}

@media (max-width: 720px) {
  .customCheckbox .ant-checkbox-inner {
    width: 8px;
    height: 8px;
  }

  .customCheckbox {
    top: -5px;
    left: 12px;
  }
}

@media (max-width: 643px) {
  .customCheckbox .ant-checkbox-inner {
    width: 5px;
    height: 5px;
  }

  .customCheckbox {
    top: -10px;
    left: 10px;
  }
}
