.titleContainer {
  display: flex;
  justify-content: left;
  font-size: 200%;
  font-weight: bold;
  font-family: 'SF UI Display', sans-serif;
  margin-left: 5%;
  margin-right: 6%;
  padding-top: 3%;
}
.underLineTitle {
  display: flex;
  background-color: black;
  height: 1px;
  width: 91%;
  margin-top: 1%;
  margin-left: 5%;
  margin-right: 6%;
}
@media (max-width: 2460px) {
  .titleContainer {
    font-size: 250%;
  }
}
@media (max-width: 1436px) {
  .titleContainer {
    font-size: 125%;
  }
}
@media (max-width: 1027px) {
  .titleContainer {
    font-size: 95%;
  }
}
@media (max-width: 909px) {
  .titleContainer {
    font-size: 70%;
  }
}
@media (max-width: 676px) {
  .titleContainer {
    font-size: 100%;
  }
}
