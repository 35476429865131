.mainContainer {
  height: auto;
  background-color: #f5f5f5;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 90vw;
}

.header {
  margin-top: 30px;
  padding-left: 50px;
  display: flex;
}



.vector {
  margin-top: 3px;
  cursor: pointer;
  padding-left: 22px;
}

.subHeader {
  display: flex;
  flex-direction: row;
  padding-left: 17px;
}

.tools {
  padding-left: 10px;
  padding-right: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0;
  margin-bottom: 0;
}
.training {
  padding-right: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0;
  margin-bottom: 0;
}
.details {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: black;
  margin-top: 0;
  margin-bottom: 0;
}

.detailsTitle {
  display: flex;
  flex-direction: column;
}

.title {
  display: flex;
  padding-left: 100px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #000000;
}

.line {
  margin: auto;
  width: 90%;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.closeModelText {
  display: flex;
  padding-left: 90px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
}

.trainingText {
  color: #22a89e;
}

.search {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 50px;
}
.searchIconImage {
  padding-right: 5px;
}
.inputsColumn {
  display: flex;
  flex-direction: row;
  padding-top: 3px;
  padding-left: 35px;
  margin-right: 8px;
}
.inputs {
  margin-right: 15px;
  padding-left: 10px;
  background-color: rgba(0, 0, 0, 0.07);
  height: 28px;
  border-radius: 6px;
  border: none;
}

.table {
  margin: 0 auto;
  width: 90%;
  padding-top: 20px;
}

.id {
  color: #22a89e;
}

.editIcon {
  cursor: pointer;
}
.deleteIcon {
  cursor: pointer;
}

@media (max-width: 1032px) {
  .inputs {
    margin-left: 5px;
    margin-top: 5px;
  }
}

@media (max-width: 584px) {
  .mainContainer {
    height: auto;
  }
  .search {
    flex-direction: column;
    justify-content: center;
  }

  .inputsColumn {
    padding-left: 0;
  }
}

@media (max-width: 464px) {
  .detailsTitle {
    justify-content: center;
  }

  .closeModelText {
    padding-left: 0;
    justify-content: center;
  }

  .title {
    padding-left: 0;
    font-size: 15px;
    justify-content: center;
  }
  .tools {
    font-size: 12px;
  }
  .training {
    font-size: 12px;
  }
  .details {
    font-size: 12px;
  }
  .header {
    margin-top: 30px;
    padding-left: 10px;
  }

  .inputs {
    width: 30vw;
  }

  .search {
    padding-left: 10px;
  }
}
