.mainContainer {
  background-color: #f5f5f5;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.header {
  margin-top: 30px;
  display: flex;
}
.vector {
  margin-top: 3px;
  cursor: pointer;
  padding-left: 10px;
}
.subHeader {
  display: flex;
  flex-direction: row;
  padding-left: 6px;
}
.users {
  padding-left: 12px;
  padding-right: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: rgba(0, 0, 0, 0.5);
  margin-top: 0;
  margin-bottom: 0;
}

.details {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: black;
  margin-top: 0;
  margin-bottom: 0;
}

@media (max-width: 1000px) {
  .mainContainer {
    flex-direction: column;
    align-items: center;
  }

  .images,
  .tables {
    align-items: center;
    justify-content: center;
    min-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .perspectiveTable,
  .layerTable,
  .mockup,
  .imageList {
    margin-top: 3%;
    min-width: 90%;
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
}
.addLayerButton{
  margin-bottom: 10px;
}
.addPerspectiveButton{
  margin-bottom: 10px;
}
.uploadImage{
  margin-top: 8px;
}
.imagePreview{
  width: 100%;
}
.tables {
  display: flex;
  gap: 1%;
  width: 100%;
  margin-top: 3%;
}

.perspectiveTable {
  width: 48.5%;
  margin-left: 1%;
}

.layerTable {
  width: 48.5%;
  margin-right: 1%;
}

.images {
  display: flex;
  gap: 1%;
  width: 100%;
  margin-top: 3%;
}

.mockup {
  width: 48.5%;
  margin-left: 1%;
  margin-bottom: 50px;
}

.imageList {
  width: 48.5%;
  margin-right: 1%;
}

.tools {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
}

.toolItem {
  display: flex;
  gap: 1%;
}
