.floatingButton {
  position: absolute;
  right: 20px;
  bottom: 10px;
  width: 60px;
  height: 60px;
}

.floatingButton .ant-float-btn-body {
  background-color: #22a89e !important;
}

.floatingButton .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
  width: 30px !important;
}

.plusIcon {
  color: white;
  font-size: 25px;
}

@media (max-width: 720px) {
  .floatingButton {
    bottom: -80px;
    width: 50px;
    height: 50px;
  }

  .floatingButton .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
    width: 25px !important;
  }

  .plusIcon {
    font-size: 23px;
  }
}

@media (max-width: 643px) {
  .floatingButton {
    bottom: -50px;
    width: 50px;
    height: 50px;
  }

  .floatingButton .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
    width: 25px !important;
  }
  .plusIcon {
    font-size: 23px;
  }
}

@media (max-width: 400px) {
  .floatingButton {
    bottom: -160px;
    width: 40px;
    height: 40px;
  }

  .floatingButton .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
    width: 20px !important;
  }
  .plusIcon {
    font-size: 20px;
  }
}
