.container {
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  display: flex;
  margin-right: 1%;
  margin-left: 1%;
}
.subContainer {
  width: 250px;
  height: 250px;
}
.pieSize {
  width: 50%;
  height: 50%;
}
@media (max-width: 961px) {
  .subContainer {
    width: 150px;
    height: 150px;
    margin-right: 1%;
    margin-left: 1%;
  }
}
