.mainContainer {
  display: flex;
  min-width: 250px;
  height: 100vh;
  flex-direction: column;
}

.titleContainer {
  height: 15%;
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
}

.title {
  padding-top: 0px;
  padding-bottom: 20px;
  font-size: 35px;
  font-family: 'SF UI Display Bold', serif;
  font-weight: 900;
}

.dashboard {
  font-size: 20px;
  font-family: 'SF UI Display', serif;
  padding-top: 25px;
  padding-bottom: 25px;
}

.menuContainer {
  height: 75%;
}

.icon {
  width: 20px;
}

.modelIcon{
  width: 20px !important;
  height: 20px !important;
  font-size: 20px !important;
}

.offerIcon{
  width:23px;
}
.order{
   width: 40px;
  height: 40px;
}
.dashboardIcon {
  width: 18px;
}

.users {
  font-size: 20px;
  font-family: 'SF UI Display', serif;
  padding-top: 25px;
  padding-bottom: 25px;
}

.helpContainer {
  display: flex;
  position: fixed;
  bottom: 0;
  align-items: center;
  height: 10%;
  width: 12.5vw;

}

.help {
  color: #00000080;
  font-size: 16px;
  font-family: 'SF UI Display', serif;
  padding-left: 7px;
  cursor: pointer;
}

.logout{
  color: #00000080;
  font-size: 16px;
  font-family: 'SF UI Display', serif;
  padding-left: 7px;
  cursor: pointer;
  margin-left: 10px;
}

.helpIcon {
  margin-left: 25px;
  cursor: pointer;
  width: 20px;
}

.tools {
  font-size: 20px;
  font-family: 'SF UI Display', serif;
  padding-top: 2px;
  padding-bottom: 2px;
}

.subTool {
  font-size: 16px;
  font-family: 'SF UI Display', serif;
}

.container {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.subContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.columContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 2%;
  flex-wrap: wrap;
}

.aiImage {
  font-size: 20px;
  font-family: 'SF UI Display', serif;
  padding-top: 25px;
  padding-bottom: 25px;
}

.galleryIcon {
  font-size: 20px;
  font-family: 'SF UI Display', serif;
  padding-top: 25px;
  padding-bottom: 25px;
}
.faqSection{
  /*margin-bottom: 200px;*/
}

@media (max-width: 1300px) {
  .title {
    padding-top: 10px;
    padding-bottom: 20px;
    font-size: 25px;
    font-family: 'SF UI Display Bold', serif;
  }
}

@media (max-width: 1100px) {
  .title {
    font-size: 25px;
  }
}

@media (max-width: 500px) {
  .titleContainer {
    padding-left: 15px;
  }

  .mainContainer {
    min-width: 150px;
  }

  .dashboard {
    font-size: 15px;
    display: flex;
    padding: 0;
  }

  .users {
    font-size: 15px;
    padding: 0;
  }

  .tools {
    font-size: 15px;
    padding: 0;
  }

  .aiImage {
    font-size: 15px;
    padding: 0;
  }

  .galleryIcon {
    font-size: 15px;
    padding: 0;
  }

  .help {
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .title {
    padding-top: 15px;
    padding-bottom: 20px;
    font-size: 20px;
    font-family: 'SF UI Display Bold', serif;
  }
}

@media (max-width: 300px) {
  .title {
    font-size: 20px;
  }

  .titleContainer {
    padding-left: 10px;
  }
}

@font-face {
  font-family: 'SF UI Display';
  src: local('fontsfree'), url('../../../src/assets/fonts/FontsFree-Net-SF-UI-Display-Regular-1.ttf') format('truetype');
}

@font-face {
  font-family: 'SF UI Display Bold';
  src: local('fontsfree'), url('../../../src/assets/fonts/FontsFree-Net-SF-UI-Text-Heavy.ttf') format('truetype');
}
