.ant-modal-root .ant-modal-centered .ant-modal {
  justify-content: center;
  margin: 0 auto;
  text-align: center !important;
}

.training-modal .ant-modal-content {
  text-align: start;
  background-color: #e4e4e4;
  display: inline-flex;
}

.textContainer {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  flex-direction: column;
}

.container {
  display: flex;
  flex-direction: column;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.title {
  margin-top: 3px;
  font-weight: bold;
  margin-bottom: 2px;
}

.row{
  display: flex;
  flex-direction: row;
}

.imageResizeMethod{
  display: flex;
  justify-content: center;
}

.input {
  background-color: #f5f5f5;
  padding-bottom: 10px;
  padding-top: 10px;
  border: 0;
  border-radius: 6px;
  margin-bottom: 5px;
  padding-left: 10px;
}

.activeBox {
  margin-right: 6px;
  font-size: 20px;
}

.enterContainer {
  display: flex;
  justify-content: center;
}

.submitButton {
  background-color: #22a89e;
  font-family: 'Nunito', sans-serif;
  color: white;
  width: 350px;
  height: 40px;
  margin-top: 10px;
}

@media (max-width: 460px) {
  .submitButton {
    width: 250px;
  }
}

@media (max-width: 330px) {
  .submitButton {
    width: 200px;
  }
}
