.mainContainer {
    justify-content: center;
    height: auto;
    background-color: #f5f5f5;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 90vw;
}

.promptFormContainer{
     width: 76%;
}

.promptFormItems{
    max-width: 100%;
}

.promptContainer{
     margin-left: 2%;
    margin-right: 2%;
    display: flex;
    width: 96% ;
}

.title {
    display: flex;
    padding-left: 42px;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #000000;
}

.separator{
    margin-top: 10px;
    margin-bottom: 10px;
}

.line {
    margin: auto;
    width: 95%;
    border: 1px solid rgba(0, 0, 0, 0.4);
}

.table {
    margin: 0 auto;
    width: 96%;
    padding-top: 20px;
}

.prompts{
    display: inline-block !important;
    margin-right: 2% !important;
    margin-left: 2% !important;
}

.textArea {
    width: 76% !important;
}

.convertButton {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 4px;
}

.promptCategories{
    width:16%
}

.imageValue{
    border-radius: 50%;
}

.loading{
    display: flex;
    align-items: center;
    justify-content: center;
}

.getLabTools {
    display: flex;
    justify-content: space-between;
    width: 96% !important;
    margin-left: 2% !important;
}
.form{
    display: flex;
    flex-wrap: wrap;
    gap: 10px ;
}

.items{
    width:12%;
    min-width: 12%;
}

.description{
    width: 40%;
}
.subcontainers {
    padding-right: 2% !important;
    padding-left: 2% !important;
}

.tools {
    display: flex;
    justify-content: flex-end;
    margin-right:2%;

}

.button {
    background-color: #22A89E ;
    width: 190px;
}

.popupImageContainer {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2%;
    margin-bottom: 2%;
    justify-content: center;
    align-items: center;
}

.popupImageItem {
    width: 210px !important;
    height: 210px !important;
    margin: 0.5% !important;
}

.labModelPropertiesContainer{
    margin: 0.5% !important;
}

@media (max-width: 700px){
    .title {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width: 584px) {
    .mainContainer {
        height: auto;
    }
}

@media (max-width: 464px) {
    .title {
        padding-left: 0;
        justify-content: center;
    }
}

