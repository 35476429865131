.error{
    color: #ed4444;
}

.submitButton {
    background-color: #22a89e;
    font-family: 'Nunito', sans-serif;
    color: white;
    width: 350px;
    height: 40px;
}

.input {
    background-color: #e7e7e7;
    padding-bottom: 10px;
    padding-top: 10px;
    border: 0;
    border-radius: 6px;
    margin-bottom: 15px;
    padding-left: 10px;
}

.textContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
}